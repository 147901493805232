import React, { useEffect, useState } from "react";
import { directus } from "../services/directus";
import About from "../components/About";
import Partner from "../components/Partner";
import GalleryList from "../components/GalleryList";
import Parallax from "../components/Parallax";
import Reservation from "../components/Reservartion";
import ServiceList from "../components/ServiceList";
import Testimonial from "../components/Testimonial";
import parallaxBg1 from "../shared/images/bg/parallax-bg-1.webp";
import parallaxBg2 from "../shared/images/bg/parallax-bg-2.webp";
import axios from "axios";

export default function Home() {
  const [gallerylist, setGalleryList] = useState(null);
  const [galleryText, setGalleryText] = useState(null);
  useEffect(() => {
    window.scrollTo(0, 0);

    async function fetchData() {
      const responseGalleryText = await directus.items("texts").readOne(4, {
        fields: ["title", "subtitle", "description"],
      });
      setGalleryText(responseGalleryText);

      const { data: { data: responseGalleryList } } = await axios(
        `${process.env.REACT_APP_DIRECTUS_URL}/items/services_directus_files?fields=*.*&limit=9&sort=?`
      );
      setGalleryList(responseGalleryList);
    }
    fetchData();
  }, []);

  return (
    <main>
      <About />
      <Parallax
        parallax={{
          bg: parallaxBg1,
          icon1: "icofont icofont-food-basket",
          text1: "Comida Caseira",
          icon2: "icofont icofont-salt-and-pepper",
          text2: "Tempero",
          icon3: "icofont icofont-restaurant",
          text3: "Sabor",
        }}
      />
      <ServiceList service={{ limit: 6, sort: "?" }} />
      <Parallax
        parallax={{
          bg: parallaxBg2,
          icon1: "icofont icofont-culinary",
          text1: "Comida Mineira",
          icon2: "icofont icofont-chef",
          text2: "Qualidade",
          icon3: "icofont icofont-dining-table",
          text3: "Atendimento",
        }}
      />
      <Reservation />
      {galleryText && (
        <div className="container text-center">
          <div className="section-title">
            <h1 className="font-pacifico">{galleryText.title}</h1>
            <h2>{galleryText.subtitle}</h2>
            <span>
              <i className="fa fa-empire" aria-hidden="true"></i>
            </span>
            <p dangerouslySetInnerHTML={{ __html: galleryText.description }} />
          </div>
        </div>
      )}
      <GalleryList
        gallery={gallerylist}
        breakpointCols={{ default: 3, 1100: 2, 700: 1 }}
      />
      <Testimonial />
      <Partner />
    </main>
  );
}
