import React from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { getAssetURL } from "../utils/get-asset-url";
import { animated, config, useSpring } from "react-spring";
import Masonry from "react-masonry-css";
import Loading from "./Loading";
import { Player } from "video-react";

export default function GalleryList({ gallery, breakpointCols }) {
  const spring = useSpring({
    from: { opacity: 0 },
    to: { opacity: 1 },
    config: config.molasses,
  });
  return (
    <div id="gallery" className="gb-gallery gallery-one">
      <div className="container">
        {!gallery ? (
          <Loading />
        ) : (
          <>
            <Masonry
              breakpointCols={breakpointCols}
              className="my-masonry-grid"
              columnClassName="my-masonry-grid_column"
            >
              {gallery?.map((gallery) =>
                gallery.directus_files_id?.embed === null ? (
                  <div key={gallery.id} className="gallery2 gallery-image">
                    <animated.div style={spring}>
                      <LazyLoadImage
                        alt={gallery.directus_files_id?.title}
                        src={getAssetURL(
                          gallery.directus_files_id?.private_hash
                        )}
                      />
                    </animated.div>
                    <div className="gb-overlay">
                      <div className="overlay-content">
                        <i className="icofont icofont-open-eye"></i>
                      </div>
                    </div>
                  </div>
                ) : (
                  <Player
                    playsInline
                    poster={gallery.directus_files_id?.url}
                    src={gallery.directus_files_id?.embed.url}
                  />
                )
              )}
            </Masonry>
          </>
        )}
      </div>
    </div>
  );
}
