import React, { useEffect, useState } from "react";
import { directus } from "../services/directus";
import Loading from "../components/Loading";
import GalleryList from "../components/GalleryList";
import Button from "react-bootstrap/Button";
import PartnerComponent from "../components/Partner";
import axios from "axios";

export default function Gallery() {
  const [successstorielist, setSuccessStorieList] = useState(null);
  const [gallerylist, setGalleryList] = useState(null);
  const [activeGallerylist, setAtiveGalleryList] = useState(null);
  const [activeSuccessStorieId, setActiveSuccessStorieId] = useState(-1);
  const allSuccessStories = activeSuccessStorieId === -1;
  const defaultLimit = 12;
  const [limit, setLimit] = useState(defaultLimit);
  const hasMoreToshow = activeGallerylist?.length % 12 === 0;

  useEffect(() => {
    window.scrollTo(0, 0);

    async function fetchData() {

      const { data: { data: responseSuccessStorieList } } = await axios(
        `${process.env.REACT_APP_DIRECTUS_URL}/items/sucess_stories?fields=*.*&sort=title`
      );
      setSuccessStorieList(responseSuccessStorieList);

      const { data: { data: responseGalleryList } } = await axios(
        `${process.env.REACT_APP_DIRECTUS_URL}/items/sucess_stories_directus_files?fields=*.*&sort=?`
      );

      setGalleryList(responseGalleryList);
      setAtiveGalleryList(responseGalleryList?.slice(0, defaultLimit));
    }
    fetchData();
  }, []);

  const setActiveSuccessStorie = (id) => {
    const allSuccessStories = id === -1;
    const filteredGallerylist = gallerylist?.filter((gallery) =>
      allSuccessStories
        ? gallery.sucess_stories_id.id > 0
        : gallery.sucess_stories_id.id === id
    );
    setActiveSuccessStorieId(id);
    setAtiveGalleryList(filteredGallerylist?.slice(0, defaultLimit));
    setLimit(defaultLimit);
  };

  const setNewLimit = (newLimit) => {
    const filteredGallerylist = gallerylist?.filter((gallery) =>
      allSuccessStories
        ? gallery.sucess_stories_id.id > 0
        : gallery.sucess_stories_id.id === activeSuccessStorieId
    );
    const sumLimit = limit + newLimit;
    setAtiveGalleryList(filteredGallerylist?.slice(0, sumLimit));
    setLimit(sumLimit);
  };

  return (
    <main>
      <div className="gb-food-menu section-padding">
        <div className="container">
          {!activeGallerylist && !successstorielist ? (
            <Loading />
          ) : (
            <>
              <div className="portfolio-menu">
                <ul className="global-list" id="portfolio-menu">
                  <li
                    onClick={() => setActiveSuccessStorie(-1)}
                    className={
                      allSuccessStories
                        ? "cbp-filter-item cbp-filter-item-active"
                        : "cbp-filter-item"
                    }
                  >
                    Todos
                  </li>
                  {successstorielist?.map((successstorie) => (
                    <li
                      key={successstorie.id}
                      onClick={() => setActiveSuccessStorie(successstorie.id)}
                      className={
                        activeSuccessStorieId === successstorie.id
                          ? "cbp-filter-item cbp-filter-item-active"
                          : "cbp-filter-item"
                      }
                    >
                      {successstorie.title}
                    </li>
                  ))}
                </ul>
              </div>
              <div id="portfolio-content" className="food-menu">
                <GalleryList
                  gallery={activeGallerylist}
                  breakpointCols={{ default: 4, 1100: 2, 700: 1 }}
                />
              </div>
              {hasMoreToshow && (
                <div id="btn-show-more" className="text-center">
                  <Button
                    onClick={() => setNewLimit(defaultLimit)}
                    className="btn btn-primary"
                  >
                    Carregar mais...
                  </Button>
                </div>
              )}
            </>
          )}
        </div>
      </div>
      <PartnerComponent />
    </main>
  );
}
