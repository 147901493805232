import React, { useEffect, useState } from "react";
import { useDirectus } from "react-directus";
import { Link } from "react-router-dom";
import { toSeoUrl } from "../utils/string";
import bgMenu from "../shared/images/bg/pattern-menu.webp";
import Loading from "./Loading";
import { LazyLoadImage } from "react-lazy-load-image-component";
import axios from "axios";

export default function ServiceList({ service }) {
  const [servicelist, setServiceList] = useState(null);
  const { DirectusImage } = useDirectus();
  useEffect(() => {
    async function fetchData() {
      const { data: { data: responseServiceList } } = await axios(
        `${process.env.REACT_APP_DIRECTUS_URL}/items/services?fields=*.*&sort=${service.sort}&limit=${service.limit}`
      );
      setServiceList(responseServiceList);
    }
    fetchData();
  }, [service]);
  return (
    <div
      className="gb-services section-padding"
      style={{ backgroundImage: "url(" + bgMenu + ")" }}
    >
      <div className="container">
        <div className="row">
          {!servicelist ? (
            <Loading />
          ) : (
            <>
              {servicelist?.map((service) => (
                <div key={service.id} className="col-md-6 col-lg-4">
                  <Link
                    to={`/servicos/${toSeoUrl(
                      service.title
                    )}`}
                  >
                    <div className="service">
                      <div className="service-image">
                        <DirectusImage
                          asset={service.image.private_hash}
                          fit="cover"
                          quality="100"
                          render={({ asset, url }) => (
                            <LazyLoadImage src={url} alt={asset.title} />
                          )}
                        />
                      </div>
                      <div className="service-info">
                        <h1>{service.title}</h1>
                        <p
                          dangerouslySetInnerHTML={{
                            __html: service.description.slice(0, 173) + "...",
                          }}
                        />
                      </div>
                    </div>
                  </Link>
                </div>
              ))}
            </>
          )}
        </div>
      </div>
    </div>
  );
}
