import React, { useState, useEffect } from "react";
import Slider from "react-slick";
import "../shared/stylesheet/icofont.css";
import bg from "../shared/images/bg/testimonial-bg.webp";
import bg2 from "../shared/images/testimonial-image.png";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Loading from "./Loading";
import { LazyLoadImage } from "react-lazy-load-image-component";
import axios from "axios";
export default function Testmonial() {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  const [testimonials, setTestimonial] = useState(null);

  useEffect(() => {
    async function fetchData() {
      const { data: { data: responseTestimonial } } = await axios(
        `${process.env.REACT_APP_DIRECTUS_URL}/items/testimonials?fields=*.*&sort=?`
      );
      setTestimonial(responseTestimonial);
    }
    fetchData();
  }, []);
  return (
    <div
      className="gb-testimonial testimonial-one section-before section-padding
      gb-parallax"
      style={{ backgroundImage: "url(" + bg + ")" }}
    >
      <div className="container">
        <div className="row">
          <div className="col-lg-7">
            <div className="testimonial-content">
              <h1 className="font-pacifico">
                Depoimentos sobre o Petisco da Deia
              </h1>
              <div className="testimonial-slider">
                {!testimonials ? (
                  <Loading />
                ) : (
                  <Slider {...settings}>
                    {testimonials?.map((testimonial) => (
                      <div key={testimonial.id} className="testimonial">
                        <div className="float-left">
                          <i
                            className="fa fa-quote-left"
                            aria-hidden="true"
                          ></i>
                        </div>
                        <div className="media-body">
                          <p
                            className="media-body-description"
                            dangerouslySetInnerHTML={{
                              __html: testimonial.description,
                            }}
                          />
                          <div className="testimonial-title">
                            <h2>
                              {testimonial.author}
                              {testimonial.position && (
                                <span>{testimonial.position}</span>
                              )}
                              {testimonial.company && (
                                <span>{testimonial.company}</span>
                              )}
                            </h2>
                          </div>
                        </div>
                      </div>
                    ))}
                  </Slider>
                )}
              </div>
            </div>
          </div>
          <div className="col-sm-6 testimonial-image text-center">
            <LazyLoadImage src={bg2} alt="Petisco da Deia" className="img-fluid" />
          </div>
        </div>
      </div>
    </div>
  );
}
